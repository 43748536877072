/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';

const IconInstitutionSchulAufsicht = ({
                                    strokeWidth = '8',
                                    height = '32',
                                    width = '32',
                                    className = '',
                                  }) => (
  <svg strokeWidth={strokeWidth} className={className} width={width} height={height} viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <style>{`.cls-1 {fill: #000!important;stroke: #000;stroke-width: 4px;}`}</style>
    </defs>
    <path className="cls-1" d="m235.52,177.26h-13.44v-10.46c0-2.47-2.01-4.48-4.48-4.48h-11.45v-87.14c0-4.12-3.35-7.47-7.47-7.47h-47.3v-7.97h6.47c2.47,0,4.48-2.01,4.48-4.48v-7.97c0-2.47-2.01-4.48-4.48-4.48h-75.68c-2.47,0-4.48,2.01-4.48,4.48v7.97c0,2.47,2.01,4.48,4.48,4.48h5.48v7.97h-47.3c-4.12,0-7.47,3.35-7.47,7.47v87.14h-10.46c-2.47,0-4.48,2.01-4.48,4.48v10.46H4.48c-2.47,0-4.48,2.01-4.48,4.48v10.95c0,2.47,2.01,4.48,4.48,4.48h231.04c2.47,0,4.48-2.01,4.48-4.48v-10.95c0-2.47-2.01-4.48-4.48-4.48Zm-36.85-104.56c1.37,0,2.49,1.12,2.49,2.49v87.14h-49.79v-89.63h47.3Zm-116.02-17.93v-6.97h74.69v6.97h-74.69Zm9.96,4.98h53.78v102.57h-3.98v-30.37c0-2.47-2.01-4.48-4.48-4.48h-35.85c-2.47,0-4.48,2.01-4.48,4.48v30.37h-4.98V59.75Zm44.81,102.57h-34.85v-29.88h34.85v29.88ZM37.84,75.19c0-1.37,1.12-2.49,2.49-2.49h47.3v89.63h-49.79v-87.14Zm-14.94,92.12h194.19v9.96H22.9v-9.96Zm212.12,24.9H4.98v-9.96h230.04v9.96Z"/>
    <path className="cls-1" d="m102.07,102.57h35.85c2.47,0,4.48-2.01,4.48-4.48v-15.93c0-2.47-2.01-4.48-4.48-4.48h-35.85c-2.47,0-4.48,2.01-4.48,4.48v15.93c0,2.47,2.01,4.48,4.48,4.48Zm.5-19.92h34.85v14.94h-34.85v-14.94Z"/>
    <path className="cls-1" d="m53.28,87.63h-5.98c-2.47,0-4.48,2.01-4.48,4.48v15.93c0,2.47,2.01,4.48,4.48,4.48h5.98c2.47,0,4.48-2.01,4.48-4.48v-15.93c0-2.47-2.01-4.48-4.48-4.48Zm-.5,19.92h-4.98v-14.94h4.98v14.94Z"/>
    <path className="cls-1" d="m78.17,87.63h-5.98c-2.47,0-4.48,2.01-4.48,4.48v15.93c0,2.47,2.01,4.48,4.48,4.48h5.98c2.47,0,4.48-2.01,4.48-4.48v-15.93c0-2.47-2.01-4.48-4.48-4.48Zm-.5,19.92h-4.98v-14.94h4.98v14.94Z"/>
    <path className="cls-1" d="m53.28,127.47h-5.98c-2.47,0-4.48,2.01-4.48,4.48v15.93c0,2.47,2.01,4.48,4.48,4.48h5.98c2.47,0,4.48-2.01,4.48-4.48v-15.93c0-2.47-2.01-4.48-4.48-4.48Zm-.5,19.92h-4.98v-14.94h4.98v14.94Z"/>
    <path className="cls-1" d="m78.17,127.47h-5.98c-2.47,0-4.48,2.01-4.48,4.48v15.93c0,2.47,2.01,4.48,4.48,4.48h5.98c2.47,0,4.48-2.01,4.48-4.48v-15.93c0-2.47-2.01-4.48-4.48-4.48Zm-.5,19.92h-4.98v-14.94h4.98v14.94Z"/>
    <path className="cls-1" d="m160.83,112.53h5.98c2.47,0,4.48-2.01,4.48-4.48v-15.93c0-2.47-2.01-4.48-4.48-4.48h-5.98c-2.47,0-4.48,2.01-4.48,4.48v15.93c0,2.47,2.01,4.48,4.48,4.48Zm.5-19.92h4.98v14.94h-4.98v-14.94Z"/>
    <path className="cls-1" d="m185.73,112.53h5.98c2.47,0,4.48-2.01,4.48-4.48v-15.93c0-2.47-2.01-4.48-4.48-4.48h-5.98c-2.47,0-4.48,2.01-4.48,4.48v15.93c0,2.47,2.01,4.48,4.48,4.48Zm.5-19.92h4.98v14.94h-4.98v-14.94Z"/>
    <path className="cls-1" d="m160.83,152.37h5.98c2.47,0,4.48-2.01,4.48-4.48v-15.93c0-2.47-2.01-4.48-4.48-4.48h-5.98c-2.47,0-4.48,2.01-4.48,4.48v15.93c0,2.47,2.01,4.48,4.48,4.48Zm.5-19.92h4.98v14.94h-4.98v-14.94Z"/>
    <path className="cls-1" d="m185.73,152.37h5.98c2.47,0,4.48-2.01,4.48-4.48v-15.93c0-2.47-2.01-4.48-4.48-4.48h-5.98c-2.47,0-4.48,2.01-4.48,4.48v15.93c0,2.47,2.01,4.48,4.48,4.48Zm.5-19.92h4.98v14.94h-4.98v-14.94Z"/>
  </svg>
);


IconInstitutionSchulAufsicht.propTypes = {
  strokeWidth: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
};

export default IconInstitutionSchulAufsicht;
