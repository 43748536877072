/* eslint-disable */

import testFilters from "../Components/LocationContext/testFilters";
import testKoops from "../Components/LocationContext/testKoops";
import testLocations from "../Components/LocationContext/testLocations";

const filteredResponseTEST = {
    'success': true,
    'data': {
        filters: testFilters,
        entries: testLocations
    },
};

const initialResponseTEST = {
    'success': true,
    'data': {
        filters: testFilters,
        entries: testKoops,
    },
};

const fetchOptions = {};


export const getInitialContent = async () => {
    let url =  `${process.env.REACT_APP_REST_URL}/smk_sachsen.api/initial_content`;
    const port = process.env.REACT_APP_REST_PORT;

    if (port && port !== '') {
        url = `:${port}${process.env.REACT_APP_REST_URL}/smk_sachsen.api/initial_content`
    }

    return fetch(url, fetchOptions).then(rsp => {
        if (rsp.ok) {
            return rsp.json();
        }
        throw ({
            message: 'Error',
            status: rsp.status
        });    
    });
};

export const getLocations = (filterValues) => {

    const params = new URLSearchParams();

    Object.entries(filterValues).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((item, index) => {
                params.append(`${key}_${index}`, item.value);
            });
        } else if (value && value !== '') {
            params.append(key, value);
        }
    });

    const queryString = params.toString();
    const url = `${process.env.REACT_APP_REST_URL}/smk_sachsen.api/filter_content?` + queryString;

    return fetch(url, fetchOptions).then(rsp => rsp.json());
};
