const testFilters = {
    schoolName: {
        label: 'Schulname',
        id: 'schoolName',
    },
    schoolLocation: {
        label: 'Schulort',
        id: 'schoolLocation',
    },
    schoolCategory: {
        options: [
            { value: 'a', label: 'A', selected: true },
            { value: 'b', label: 'B', selected: false },
            { value: 'c', label: 'C', selected: false },
        ],
    },
    schoolType: {
        options: [
            {
                schoolCategory: 'a',
                value: 'schoolType1',
                label: 'Berufsschule1',
                selected: false,
            },
            {
                schoolCategory: 'b',
                value: 'schoolType2',
                label: 'Grundschule2',
                selected: false,
            },
            {
                schoolCategory: 'c',
                value: 'schoolType3',
                label: 'Berufsschule3',
                selected: false,
            },
            {
                schoolCategory: 'a',
                value: 'schoolType4',
                label: 'Grundschule4',
                selected: false,
            },
        ],
    },
    focalPointOfSupport: {
        options: [
            { value: 'a', label: 'A', selected: true },
            { value: 'b', label: 'B', selected: false },
            { value: 'c', label: 'C', selected: false },
        ],
    },
    schoolCommunity: {
        options: [
            { value: 'a', label: 'A', selected: true },
            { value: 'b', label: 'B', selected: false },
            { value: 'c', label: 'C', selected: false },
        ],
    },
    schoolCommunityPart: {
        options: [
            { value: 'a', label: 'A', selected: true },
            { value: 'b', label: 'B', selected: false },
            { value: 'c', label: 'C', selected: false },
        ],
    },
    schoolOwner: {
        options: [
            { value: 'a', label: 'A', selected: true },
            { value: 'b', label: 'B', selected: false },
            { value: 'c', label: 'C', selected: false },
        ],
    },
    schoolInspectorate: {
        options: [
            { value: 'a', label: 'A', selected: true },
            { value: 'b', label: 'B', selected: false },
            { value: 'c', label: 'C', selected: false },
        ],
    },
    schoolLegalStatus: {
        options: [
            { value: 'a', label: 'A', selected: true },
            { value: 'b', label: 'B', selected: false },
            { value: 'c', label: 'C', selected: false },
        ],
    },
    schoolAccessibility: {
        options: [
            { value: 'a', label: 'A', selected: true },
            { value: 'b', label: 'B', selected: false },
            { value: 'c', label: 'C', selected: false },
        ],
    },
    schoolMeasures: {
        options: [
            { value: 'a', label: 'A', selected: true },
            { value: 'b', label: 'B', selected: false },
            { value: 'c', label: 'C', selected: false },
        ],
    },
    institutions: {
        options: [
            { value: 'a', label: 'A', selected: true },
            { value: 'b', label: 'B', selected: false },
            { value: 'c', label: 'C', selected: false },
        ],
    },
    schoolServiceCenter: {
        options: [
            { value: 'a', label: 'A', selected: true },
            { value: 'b', label: 'B', selected: false },
            { value: 'c', label: 'C', selected: false },
        ],
    },
    koopv: {
        options: [
            { value: 'a', label: 'A', selected: true },
            { value: 'b', label: 'B', selected: false },
            { value: 'c', label: 'C', selected: false },
        ],
    },
    district: {
        options: [
            { value: 'a', label: 'A', selected: true },
            { value: 'b', label: 'B', selected: false },
            { value: 'c', label: 'C', selected: false },
        ],
    },
};

export default testFilters;
