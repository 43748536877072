import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import styles from './TabSwitch.module.scss';

export const Tab = ({ children, index, isSelected }) => (
    <div
        id={`tabpanel_${index}`}
        name={`tabpanel_${index}`}
        role="tabpanel"
        aria-labelledby={`tab_${index}`}
        aria-hidden={!isSelected()}
        style={{ display: isSelected() ? 'block' : 'none' }}
    >
        {children}
    </div>
);

Tab.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number,
    isSelected: PropTypes.func,
};

const TabSwitch = ({ onChange, intitialTabId = 0, children, infoline }) => {
    const [activeTabIndex, setActiveTabIndex] = useState(intitialTabId);
    const [indexChanged, setIndexChanged] = useState(false);

    const wrapperRef = useRef(null);
    const activeRef = useRef(null);

    const changeTabIndex = useCallback((newIndex) => {
        setActiveTabIndex(newIndex);
        setIndexChanged(true);
    }, []);

    useEffect(() => {
        onChange?.(children[activeTabIndex].props.name);
    }, [activeTabIndex, children, onChange]);

    useEffect(() => {
        if (indexChanged) {
            activeRef.current?.focus();
            setIndexChanged(false);
        }
    }, [indexChanged]);

    const selectTab = useCallback(
        (tabId) => {
            changeTabIndex(tabId);
        },
        [changeTabIndex]
    );

    const previousTab = useCallback(() => {
        if (activeTabIndex > 0) {
            changeTabIndex(activeTabIndex - 1);
        } else {
            changeTabIndex(children.length - 1);
        }
    }, [activeTabIndex, changeTabIndex, children.length]);

    const nextTab = useCallback(() => {
        if (activeTabIndex < children.length - 1) {
            changeTabIndex(activeTabIndex + 1);
        } else {
            changeTabIndex(0);
        }
    }, [activeTabIndex, changeTabIndex, children.length]);

    const tabKeyEvent = useCallback(
        (e, index) => {
            e.preventDefault();

            if (e.which === 13) {
                selectTab(index);
            } else if (e.which === 37) {
                previousTab();
            } else if (e.which === 39) {
                nextTab();
            }
        },
        [nextTab, previousTab, selectTab]
    );

    const handleClick = useCallback(
        (e, index) => {
            e.preventDefault();
            selectTab(index);
        },
        [selectTab]
    );

    const SingleTab = useCallback(
        ({ tab, index }) => {
            const isActive = index === activeTabIndex;
            const title = tab.props.title;

            return (
                <li role="presentation">
                    <a
                        className={`${styles.toggleButton} ${
                            isActive && styles.active
                        }`}
                        onClick={(e) => handleClick(e, index)}
                        onKeyUp={(e) => tabKeyEvent(e, index)}
                        href={`#tabpanel_${index}`}
                        disabled={!isActive}
                        type="button"
                        role="tab"
                        aria-controls={`tab_${index}`}
                        id={`tab_${index}`}
                        aria-selected={isActive}
                        tabIndex={isActive ? 0 : -1}
                        ref={(link) => {
                            if (isActive) {
                                activeRef.current = link;
                            }
                        }}
                    >
                        {title}
                    </a>
                </li>
            );
        },
        [activeTabIndex, handleClick, tabKeyEvent]
    );

    return (
        <div className={styles.switchWrapper}>
            <div className={styles.buttonsWrapper}>
                {infoline && infoline}
                <ul
                    className={styles.tabButtons}
                    ref={wrapperRef}
                    role="tablist"
                >
                    {children.map((item, index) => {
                        return (
                            <SingleTab tab={item} key={index} index={index} />
                        );
                    })}
                </ul>
            </div>
            <div>
                {children.map((tab, i) =>
                    React.cloneElement(tab, {
                        index: i,
                        key: i,
                        isSelected: () => i === activeTabIndex,
                    })
                )}
            </div>
        </div>
    );
};

TabSwitch.propTypes = {
    onChange: PropTypes.func,
    intitialTabId: PropTypes.string,
    children: PropTypes.node,
    infoline: PropTypes.node,
};

export default TabSwitch;
