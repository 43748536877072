import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import InclusionApp from './InclusionApp';

window.renderPiServiceDeskForm =
    window.renderPiServiceDeskForm ||
    function renderPiServiceDeskForm(appConfig) {
        const renderNode = document.getElementById(appConfig.containerId);

        //remove the loader node, if any
        const loaderNode = document.getElementById(
            appConfig.containerId + '-loader'
        );
        if (loaderNode) {
            loaderNode.remove();
        }

        if (!renderNode) {
            console.error('Comments: No render node found.', appConfig);

            return;
        }

        if (process.env.REACT_APP_STANDALONE === 'true') {
            const body = document.getElementById('react-app-body');
            body.classList.add('mi-full');
        }

        //replace snippet with app node
        const appNode = document.createElement('div');
        renderNode.replaceWith(appNode);

        const root = ReactDOM.createRoot(appNode);

        if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
            root.render(
                <React.StrictMode>
                    <InclusionApp />
                </React.StrictMode>
            );
        } else {
            root.render(
                <React.StrictMode>
                    <App />
                </React.StrictMode>
            );
        }
    };

window.renderPiServiceDeskForm({
    containerId: 'smk_sachsen_react_app',
});
