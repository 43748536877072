import React from 'react';
import PropTypes from 'prop-types';

// styles
import styles from './LocationListItem.module.scss';

// Helper
import {
    koopvContactPerson,
    koopvEvent,
    koopvPartners,
    koopvSchoolCounts,
    koopvWorkFocus,
    koopvWorkingGoal,
} from '../LocationDetails/koopDetails';
import {
    schoolAddress,
    schoolCategoryKey,
    schoolHomepage,
    schoolType,
    schoolKoopv,
} from '../LocationDetails/schoolDetails';
import {
    institutionAddress,
    institutionContactPerson,
} from '../LocationDetails/institutionDetails';

const LocationListItem = ({ locationObject }) => {
    // KOOPERATIONSVERBUND
    if (locationObject.categoryId === 'kooperationsverbund') {
        return (
            <div className={styles.container}>
                {/* contact_person */}
                {koopvContactPerson(locationObject, styles)}
                <hr className={styles.hr} />

                {/* work_goal */}
                {koopvWorkingGoal(locationObject, styles)}
                <hr className={styles.hr} />

                {/* work_focus */}
                {koopvWorkFocus(locationObject, styles)}
                <hr className={styles.hr} />

                {/* event */}
                {koopvEvent(locationObject, styles)}
                <hr className={styles.hr} />

                {/* partners */}
                {koopvPartners(locationObject, styles)}
                <hr className={styles.hr} />

                {/* school_counts */}
                {koopvSchoolCounts(locationObject, styles)}
            </div>
        );
    }

    // SCHULE
    if (locationObject.categoryId === 'schule') {
        return (
            <div className={styles.container}>
                {/* categoryKey / categoryLabel */}
                {schoolCategoryKey(locationObject, styles)}
                <hr className={styles.hr} />

                {schoolType(locationObject, styles)}
                <hr className={styles.hr} />

                {/* address */}
                {schoolAddress(locationObject, styles)}
                <hr className={styles.hr} />

                {/* homepage */}
                {schoolHomepage(locationObject, styles)}
                <hr className={styles.hr} />

                {/* Koopv */}
                {schoolKoopv(locationObject, styles)}
            </div>
        );
    }

    // EINRICHTUNG
    if (locationObject.categoryId === 'einrichtung') {
        return (
            <div className={styles.container}>
                {/* contact_person / contact_data */}
                {institutionContactPerson(locationObject, styles)}
                <hr className={styles.hr} />

                {/* address */}
                {institutionAddress(locationObject, styles)}
                <hr className={styles.hr} />

                {/* Koopv */}
                {schoolKoopv(locationObject, styles)}
            </div>
        );
    }
};

export default LocationListItem;
LocationListItem.propTypes = {
    locationObject: PropTypes.shape({
        categoryId: PropTypes.string,
        subCategoryIds: PropTypes.arrayOf(PropTypes.string),
        categoryKey: PropTypes.string,
        categoryLabel: PropTypes.string,
        typeLabel: PropTypes.string,
        link: PropTypes.string,
        streetName: PropTypes.string,
        houseNumber: PropTypes.string,
        postcode: PropTypes.string,
        community: PropTypes.string,
        communityPart: PropTypes.string,
        firstname: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        lastname: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        email: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        phoneCode: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        phoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        workGoal: PropTypes.string,
        workFocus: PropTypes.string,
        homepage: PropTypes.string,
        event: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
        partners: PropTypes.string,
        schoolCounts: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    }),
};
