/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';

const IconInstitutionServiceStelle = ({
                                    strokeWidth = '8',
                                    height = '32',
                                    width = '32',
                                    className = '',
                                  }) => (
  <svg strokeWidth={strokeWidth} className={className} width={width} height={height} viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <style>{`.cls-003 {fill: #000!important;stroke: #000;stroke-width: 4px;}`}</style>
    </defs>
    <path className="cls-003" d="m26.7,68.08c14.72,0,26.69-11.97,26.69-26.69s-11.97-26.69-26.69-26.69S0,26.67,0,41.39s11.97,26.69,26.69,26.69Zm0-48.49c12.02,0,21.79,9.78,21.79,21.79s-9.78,21.79-21.79,21.79S4.9,53.4,4.9,41.39s9.78-21.79,21.79-21.79Z"/>
    <path className="cls-003" d="m120,53.39c14.72,0,26.69-11.97,26.69-26.69S134.72,0,120,0s-26.69,11.97-26.69,26.69,11.97,26.69,26.69,26.69Zm0-48.49c12.02,0,21.79,9.78,21.79,21.79s-9.78,21.79-21.79,21.79-21.79-9.78-21.79-21.79,9.78-21.79,21.79-21.79Z"/>
    <path className="cls-003" d="m88.65,80.73c0-9.68,7.87-17.55,17.55-17.55h27.59c9.68,0,17.55,7.87,17.55,17.55v10.37h4.9v-10.37c0-12.38-10.07-22.45-22.45-22.45h-27.59c-12.38,0-22.45,10.07-22.45,22.45v10.37h4.9v-10.37Z"/>
    <path className="cls-003" d="m213.3,68.08c14.72,0,26.69-11.97,26.69-26.69s-11.97-26.69-26.69-26.69-26.69,11.97-26.69,26.69,11.97,26.69,26.69,26.69Zm0-48.49c12.02,0,21.79,9.78,21.79,21.79s-9.78,21.79-21.79,21.79-21.79-9.78-21.79-21.79,9.78-21.79,21.79-21.79Z"/>
    <path className="cls-003" d="m222.87,72.98h-28.32c-4.53,0-8.75,2.44-11.02,6.36l-9.77,16.91c-.43.75-1.24,1.22-2.11,1.22h-26.68c-5.67,0-10.29,4.61-10.29,10.29,0,1.98.57,3.82,1.54,5.39h-32.47c.97-1.57,1.54-3.41,1.54-5.39,0-5.67-4.61-10.29-10.29-10.29h-26.68c-.87,0-1.67-.47-2.11-1.22l-9.77-16.91c-2.27-3.92-6.49-6.36-11.02-6.36h-28.32c-7.01,0-12.72,5.71-12.72,12.72v63.91c0,11.76,9.57,21.32,21.32,21.32h37.3c2.78,0,5.05,2.26,5.05,5.05v48.43c0,8.17,6.47,14.94,14.42,15.1.09,0,.19,0,.28,0,3.87,0,7.51-1.49,10.28-4.2,2.84-2.79,4.4-6.51,4.4-10.49v-56.32c0-7.2-2.8-13.96-7.89-19.05-5.09-5.09-11.85-7.89-19.05-7.89h-22.04v-34.88l3.25,5.64c2.04,3.53,5.84,5.73,9.92,5.73h116.67c4.08,0,7.88-2.19,9.92-5.73l3.26-5.64v34.88h-22.04c-7.2,0-13.96,2.8-19.05,7.89-5.09,5.09-7.89,11.85-7.89,19.05v56.32c0,3.98,1.56,7.7,4.4,10.49,2.77,2.72,6.41,4.2,10.28,4.2.09,0,.19,0,.28,0,7.95-.15,14.42-6.92,14.42-15.1v-48.43c0-2.78,2.26-5.05,5.05-5.05h37.3c11.76,0,21.32-9.57,21.32-21.32v-63.91c0-7.01-5.71-12.72-12.72-12.72Zm-138.62,40.16h-22.58c-2.33,0-4.51-1.26-5.67-3.28l-6-10.39c-.79-1.36-2.34-2-3.86-1.6s-2.54,1.74-2.54,3.31v45.26h26.94c5.89,0,11.43,2.29,15.59,6.45s6.45,9.7,6.45,15.59v56.32c0,2.65-1.04,5.14-2.94,7-1.89,1.85-4.38,2.86-7.04,2.8-5.3-.1-9.62-4.67-9.62-10.2v-48.43c0-5.48-4.46-9.94-9.94-9.94H25.74c-9.06,0-16.43-7.37-16.43-16.43v-63.91c0-4.31,3.51-7.82,7.82-7.82h28.32c2.79,0,5.38,1.5,6.77,3.91l9.77,16.91c1.31,2.26,3.74,3.66,6.35,3.66h26.68c2.97,0,5.39,2.42,5.39,5.39s-2.42,5.39-5.39,5.39h-10.78Zm146.44,36.47c0,9.06-7.37,16.43-16.43,16.43h-37.3c-5.48,0-9.94,4.46-9.94,9.94v48.43c0,5.52-4.31,10.1-9.61,10.2-2.67.09-5.15-.95-7.04-2.8-1.89-1.86-2.94-4.34-2.94-7v-56.32c0-5.89,2.29-11.43,6.45-15.59s9.7-6.45,15.59-6.45h26.94v-45.26c0-1.57-1.02-2.9-2.54-3.31-1.52-.41-3.07.23-3.86,1.6l-6,10.39c-1.17,2.02-3.34,3.28-5.67,3.28h-33.36c-2.97,0-5.39-2.42-5.39-5.39s2.42-5.39,5.39-5.39h26.68c2.61,0,5.04-1.4,6.35-3.66l9.77-16.91c1.39-2.41,3.99-3.91,6.77-3.91h28.32c4.31,0,7.82,3.51,7.82,7.82v63.91Z"/>
  </svg>
);


IconInstitutionServiceStelle.propTypes = {
  strokeWidth: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
};

export default IconInstitutionServiceStelle;
