import React from 'react';
import './assets/stylesheets/index.scss';

import LocationContextProvider from './Components/LocationContext/LocationContext';
import LocationIndex from './Components/LocationIndex/LocationIndex';

import './assets/stylesheets/InclusionApp/main.21422625.css';

function InclusionApp() {
    return (
        <>
            <div className="main main-layout-1-3-0" id="main">
                <div className="main-container">
                    <div className="row">
                        {/* aside left */}
                        <div
                            className="sidebar sidebar-left"
                            id="main-sidebar-left"
                        >
                            <div className="row">
                                <div className="col">
                                    <div
                                        className="box box-nav-portal affix-top"
                                        data-plugin="affix-navi"
                                        id="contentnav-portal"
                                        style={{ top: 0 }}
                                    >
                                        <a
                                            className="sr-only"
                                            data-auto-event-observed="true"
                                            data-bcup-haslogintext="no"
                                            id="a-navigation"
                                            href="/"
                                        >
                                            Portalnavigation
                                        </a>
                                        <div className="box-header">
                                            <a
                                                className="portal"
                                                data-auto-event-observed="true"
                                                data-bcup-haslogintext="no"
                                                href="https://www.inklusion.bildung.sachsen.de/index.html"
                                                title="in eigenes Web-Portal wechseln"
                                            >
                                                Inklusion
                                                <span className="sr-only">
                                                    (in eigenes Web-Portal
                                                    wechseln)
                                                </span>
                                            </a>
                                        </div>
                                        <div className="box-body">
                                            <nav
                                                aria-labelledby="a-navigation"
                                                className="nav nav-portal"
                                                data-element="nav-portal"
                                            >
                                                <ul className="list-links">
                                                    <li className="ancestor">
                                                        <a
                                                            data-auto-event-observed="true"
                                                            data-bcup-haslogintext="no"
                                                            href="https://www.inklusion.bildung.sachsen.de/aufbau-von-kooperationsverbunden-5910.html"
                                                        >
                                                            Kooperationsverbünde
                                                        </a>
                                                        <ul className="list-links">
                                                            <li>
                                                                <a
                                                                    data-auto-event-observed="true"
                                                                    data-bcup-haslogintext="no"
                                                                    href="https://www.inklusion.bildung.sachsen.de/grundlagen-5920.html"
                                                                >
                                                                    Grundlagen
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    data-auto-event-observed="true"
                                                                    data-bcup-haslogintext="no"
                                                                    href="https://www.inklusion.bildung.sachsen.de/umsetzung-in-den-regionen-5914.html"
                                                                >
                                                                    Umsetzung in
                                                                    den Regionen
                                                                </a>
                                                            </li>
                                                            <li
                                                                aria-current="page"
                                                                className="active"
                                                            >
                                                                <span className="active-label">
                                                                    <strong
                                                                        style={{
                                                                            fontWeight:
                                                                                'normal',
                                                                        }}
                                                                    >
                                                                        Interaktive
                                                                        Karte
                                                                    </strong>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="content-wrapper"
                            id="main-content-wrapper"
                        >
                            <div className="row">
                                {/* Wird in Targetcontainer-Seiten genutzt */}
                                <main className="content" id="main-content">
                                    <a
                                        className="sr-only"
                                        data-auto-event-observed="true"
                                        data-bcup-haslogintext="no"
                                        id="a-inhaltsbereich"
                                        href="/"
                                    >
                                        Hauptinhalt
                                    </a>
                                    <h1 id="page-title">Interaktive Karte</h1>
                                    {/* alt+0173 */}
                                    {/* content */}
                                    <a
                                        data-auto-event-observed="true"
                                        data-bcup-haslogintext="no"
                                        id="a-7287"
                                        href="/"
                                    >
                                        _
                                    </a>
                                    <div className="row">
                                        <figure className="figure-col figure-col-right size-xs-half-1 figure-float">
                                            <img
                                                alt="Karte von Sachsen mit einer farbigen Darstellung des Netzwerkes der Kooperationsverbünde."
                                                className="media-object hidden-no-js"
                                                data-grid-image="true"
                                                data-size="figure-col-right size-xs-half-1"
                                                data-src-maxwidth={1760}
                                                src="https://www.inklusion.bildung.sachsen.de/img/Karte_Sachsen_Kooperationsverbuendete_rdax_1095x774_87s.jpg"
                                                title="Farbige Karte der Kooperationsverbünde in Sachsen"
                                            />
                                            <button
                                                aria-haspopup="true"
                                                aria-label="Vollbildmodus öffnen: Karte von Sachsen mit einer farbigen Darstellung des Netzwerkes der Kooperationsverbünde."
                                                className="btn btn-image-fullscreen"
                                                data-bcup-haslogintext="no"
                                                data-element="btnFullscreen"
                                                title="Vollbildmodus öffnen"
                                                type="button"
                                            >
                                                _
                                            </button>
                                        </figure>
                                        <div className="text-col">
                                            <p>
                                                Alle allgemein- und
                                                berufsbildenden Schulen in
                                                öffentlicher Trägerschaft bilden
                                                zur Sicherung und Ausgestaltung
                                                der sonderpädagogischen
                                                Förderung und des inklusiven
                                                Unterrichts gemeinsam mit den
                                                Landkreisen und Kreisfreien
                                                Städten sowie den Schulträgern
                                                64 Kooperationsverbünde in
                                                Sachsen. Die auf der rechten
                                                Seite eingebundene statische
                                                Karte verdeutlicht die
                                                Abgrenzungen der
                                                Kooperationsverbünde anhand
                                                unterschiedlicher Farben und
                                                hilft so bei einer ersten
                                                Orientierung.
                                            </p>
                                            <p>
                                                Nutzen Sie für Ihre Suche die
                                                zahlreichen Filter.&nbsp;
                                                Zusätzliche Informationen zu
                                                einem Kooperationsverbund, einer
                                                Schule oder sonstigen
                                                Einrichtung erhalten Sie, wenn
                                                Sie auf den jeweiligen
                                                Standort-Pin klicken.
                                            </p>
                                            <p>
                                                Bitte beachten Sie, dass ein
                                                bestimmtes Suchergebnis nicht
                                                automatisch zur Aufnahme von
                                                weiteren Schülerinnen und
                                                Schülern in die inklusive
                                                Unterrichtung an dieser Schule
                                                führt.
                                            </p>
                                            <p>
                                                Das Landesamt für Schule Bildung
                                                berät Eltern, in welcher
                                                Schulart und in welcher Schule
                                                dem individuellen
                                                sonderpädagogischen Förderbedarf
                                                der Schülerin oder des Schülers
                                                entsprochen werden kann. Zudem
                                                sind an der Entscheidung zur
                                                Aufnahme verschiedene Partner
                                                beteiligt. Dazu wird in § 4c
                                                Absatz 6 Sächsisches Schulgesetz
                                                ausgeführt: »Über die Aufnahme
                                                des Schülers an eine bestimmte
                                                Schule entscheidet der
                                                Schulleiter. […] Kommt auf Grund
                                                der Abstimmungen im
                                                Kooperationsverbund keine
                                                Aufnahme zustande, kann die
                                                Schulaufsichtsbehörde nach
                                                Anhörung der Eltern oder des
                                                volljährigen Schülers mit
                                                sonderpädagogischem Förderbedarf
                                                und des Trägers der
                                                Schülerbeförderung im Benehmen
                                                mit dem Schulleiter und dem
                                                Schulträger den Ort der
                                                inklusiven Unterrichtung
                                                festlegen.«
                                            </p>
                                            <p>
                                                Sie haben Fragen?{' '}
                                                <a
                                                    data-auto-event-observed="true"
                                                    data-bcup-haslogintext="no"
                                                    href="https://www.inklusion.bildung.sachsen.de/kontakt-4116.html"
                                                >
                                                    Dann kommen Sie gern auf uns
                                                    zu.
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    <a
                                        data-auto-event-observed="true"
                                        data-bcup-haslogintext="no"
                                        id="a-7312"
                                        href="/"
                                    >
                                        _
                                    </a>
                                    <div className="embed hidden-print">
                                        <div className="embed-item">
                                            <LocationContextProvider>
                                                <LocationIndex />
                                            </LocationContextProvider>
                                        </div>
                                    </div>
                                    <a
                                        className="link-scroll-to-top"
                                        data-auto-event-observed="true"
                                        data-bcup-haslogintext="no"
                                        data-scroll-to-top=""
                                        href="https://www.inklusion.bildung.sachsen.de/interaktive-karte.html#header"
                                        style={{ display: 'none' }}
                                        title="zurück zum Seitenanfang"
                                    >
                                        <img
                                            alt="zurück zum Seitenanfang"
                                            className="svg-img"
                                            src="https://www.inklusion.bildung.sachsen.de/img/icon-totop.svg"
                                        />
                                    </a>
                                </main>
                                {/* aside right */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer" id="footer">
                <a
                    className="sr-only"
                    data-auto-event-observed="true"
                    data-bcup-haslogintext="no"
                    id="a-footer"
                    href="/"
                >
                    Service
                </a>
                <div className="footer-content container">
                    <div
                        aria-multiselectable="true"
                        className="row panel-group accordion-enabled"
                        id="accordion-footer"
                        role="tablist"
                    >
                        <div className="footer-col panel panel-default col-sm-40">
                            <div
                                className="panel-heading"
                                id="headingFooter1"
                                role="tab"
                            >
                                <div className="hdl-4 panel-title">
                                    <h2 className="hidden-xs h4">
                                        Herausgeber
                                    </h2>
                                    <button
                                        aria-controls="collapseFooter1"
                                        aria-expanded="false"
                                        className="visible-xs btn btn-footer"
                                        data-bcup-haslogintext="no"
                                        data-parent="#accordion-footer"
                                        data-target="#collapseFooter1"
                                        data-toggle="collapse"
                                        type="button"
                                    >
                                        Herausgeber
                                    </button>
                                </div>
                            </div>
                            <div
                                aria-labelledby="headingFooter1"
                                className="panel-collapse collapse in"
                                id="collapseFooter1"
                                role="tabpanel"
                            >
                                <div className="panel-body">
                                    <div className="media owner vcard">
                                        <div className="pull-left owner-img">
                                            <img
                                                alt="Wappen vom Freistaat Sachsen"
                                                className="svg-img"
                                                src="https://www.inklusion.bildung.sachsen.de/img/logo-sachsen-emblem.svg"
                                            />
                                        </div>
                                        <address className="media-body owner-text">
                                            <p className="owneradress">
                                                <a
                                                    className="url fn org"
                                                    data-auto-event-observed="true"
                                                    data-bcup-haslogintext="no"
                                                    href="https://www.smk.sachsen.de/"
                                                >
                                                    Sächsisches
                                                    Staatsministerium für Kultus
                                                </a>{' '}
                                                <span className="adr">
                                                    <span className="street-address">
                                                        Postfach 10 09 10
                                                    </span>
                                                    <br />
                                                    <span className="postal-code">
                                                        01079
                                                    </span>{' '}
                                                    <span className="locality">
                                                        Dresden
                                                    </span>
                                                </span>
                                            </p>
                                            <table className="table-unstyled">
                                                <tbody>
                                                    <tr className="tel">
                                                        <th className="type">
                                                            Telefon:&nbsp;
                                                        </th>
                                                        <td className="value">
                                                            <span className="nobr">
                                                                <a
                                                                    data-auto-event-observed="true"
                                                                    data-bcup-haslogintext="no"
                                                                    href="tel:+4935156465122"
                                                                >
                                                                    +49 351
                                                                    564-65122
                                                                </a>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="tel">
                                                        <th className="type">
                                                            Telefax:&nbsp;
                                                        </th>
                                                        <td className="value">
                                                            <span className="nobr">
                                                                +49 351
                                                                564-66248
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>E-Mail:&nbsp;</th>
                                                        <td>
                                                            <a
                                                                className="email"
                                                                data-auto-event-observed="true"
                                                                data-bcup-haslogintext="no"
                                                                href="mailto:poststelle@smk-sachsen.de"
                                                            >
                                                                Poststelle
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </address>
                                    </div>
                                </div>
                            </div>
                            <div className="col-wing col-wing-link-to-top visible-sm" />
                        </div>
                        <div className="clearfix visible-sm" />
                        <div className="footer-col panel panel-default">
                            <div
                                className="panel-heading"
                                id="headingFooter2"
                                role="tab"
                            >
                                <div className="hdl-4 panel-title">
                                    <h2 className="hidden-xs h4">Service</h2>
                                    <button
                                        aria-controls="collapseFooter2"
                                        aria-expanded="false"
                                        className="visible-xs btn btn-footer collapsed"
                                        data-bcup-haslogintext="no"
                                        data-parent="#accordion-footer"
                                        data-target="#collapseFooter2"
                                        data-toggle="collapse"
                                        type="button"
                                    >
                                        Service
                                    </button>
                                </div>
                            </div>
                            <div
                                aria-labelledby="headingFooter2"
                                className="panel-collapse collapse"
                                id="collapseFooter2"
                                role="tabpanel"
                            >
                                <div className="panel-body">
                                    <ul className="list-links">
                                        <li>
                                            <a
                                                data-auto-event-observed="true"
                                                data-bcup-haslogintext="no"
                                                href="https://www.inklusion.bildung.sachsen.de/ubersicht.html"
                                            >
                                                Übersicht
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                data-auto-event-observed="true"
                                                data-bcup-haslogintext="no"
                                                href="https://www.smk.sachsen.de/impressum.html"
                                            >
                                                Impressum
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                data-auto-event-observed="true"
                                                data-bcup-haslogintext="no"
                                                href="https://www.smk.sachsen.de/kontakt.html"
                                            >
                                                Kontakt
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                data-auto-event-observed="true"
                                                data-bcup-haslogintext="no"
                                                href="https://www.inklusion.bildung.sachsen.de/suche.html"
                                            >
                                                Suche
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                data-auto-event-observed="true"
                                                data-bcup-haslogintext="no"
                                                href="https://www.smk.sachsen.de/datenschutz.html"
                                            >
                                                Datenschutz
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                data-auto-event-observed="true"
                                                data-bcup-haslogintext="no"
                                                href="https://www.smk.sachsen.de/rechtliche-hinweise.html"
                                            >
                                                Rechtliche Hinweise
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                data-auto-event-observed="true"
                                                data-bcup-haslogintext="no"
                                                href="https://www.smk.sachsen.de/barrierefreiheit.html"
                                            >
                                                Barrierefreiheit
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                data-auto-event-observed="true"
                                                data-bcup-haslogintext="no"
                                                href="https://www.smk.sachsen.de/transparenz.html"
                                            >
                                                Transparenzhinweis
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="footer-col panel panel-default">
                            <div
                                className="panel-heading"
                                id="headingFooter3"
                                role="tab"
                            >
                                <div className="hdl-4 panel-title">
                                    <h2 className="hidden-xs h4">
                                        Verwandte Portale
                                    </h2>
                                    <button
                                        aria-controls="collapseFooter3"
                                        aria-expanded="false"
                                        className="visible-xs btn btn-footer collapsed"
                                        data-bcup-haslogintext="no"
                                        data-parent="#accordion-footer"
                                        data-target="#collapseFooter3"
                                        data-toggle="collapse"
                                        type="button"
                                    >
                                        Verwandte Portale
                                    </button>
                                </div>
                            </div>
                            <div
                                aria-labelledby="headingFooter3"
                                className="panel-collapse collapse"
                                id="collapseFooter3"
                                role="tabpanel"
                            >
                                <div className="panel-body">
                                    <ul className="list-links">
                                        <li>
                                            <a
                                                data-auto-event-observed="true"
                                                data-bcup-haslogintext="no"
                                                href="https://www.bildung.sachsen.de/"
                                            >
                                                Bildung
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                data-auto-event-observed="true"
                                                data-bcup-haslogintext="no"
                                                href="https://www.kita.sachsen.de/"
                                            >
                                                Kindertagespflege
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                data-auto-event-observed="true"
                                                data-bcup-haslogintext="no"
                                                href="https://www.schule.sachsen.de/"
                                            >
                                                Schule und Ausbildung
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                data-auto-event-observed="true"
                                                data-bcup-haslogintext="no"
                                                href="https://www.behindern.verhindern.sachsen.de/"
                                            >
                                                Behindern verhindern
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="footer-col panel panel-default">
                            <div
                                className="panel-heading"
                                id="headingFooter4"
                                role="tab"
                            >
                                <div className="hdl-4 panel-title">
                                    <h2 className="hidden-xs h4">
                                        Seite empfehlen
                                    </h2>
                                    <button
                                        aria-controls="collapseFooter4"
                                        aria-expanded="false"
                                        className="visible-xs btn btn-footer collapsed"
                                        data-bcup-haslogintext="no"
                                        data-parent="#accordion-footer"
                                        data-target="#collapseFooter4"
                                        data-toggle="collapse"
                                        type="button"
                                    >
                                        Seite empfehlen
                                    </button>
                                </div>
                            </div>
                            <div
                                aria-labelledby="headingFooter4"
                                className="panel-collapse collapse"
                                id="collapseFooter4"
                                role="tabpanel"
                            >
                                <div className="panel-body">
                                    <ul className="list-links">
                                        <li>
                                            <a
                                                className="social social-facebook"
                                                data-auto-event-observed="true"
                                                data-bcup-haslogintext="no"
                                                data-sl-url-tpl="https://www.facebook.com/sharer/sharer.php?u={url}&t={title}"
                                                href="/"
                                                rel="nofollow noreferrer"
                                                target="_blank"
                                                title="Facebook"
                                            >
                                                <span lang="en">Facebook</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="social social-twitter"
                                                data-auto-event-observed="true"
                                                data-bcup-haslogintext="no"
                                                data-sl-url-tpl="https://twitter.com/intent/tweet?url={url}"
                                                href="https://twitter.com/intent/tweet?url=https://www.inklusion.bildung.sachsen.de/interaktive-karte.html"
                                                rel="nofollow noreferrer"
                                                target="_blank"
                                                title="X (vormals Twitter)"
                                            >
                                                <span lang="de">
                                                    X (vormals Twitter)
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="social social-mail"
                                                data-auto-event-observed="true"
                                                data-bcup-haslogintext="no"
                                                href="/"
                                                rel="nofollow"
                                                title="E-Mail"
                                            >
                                                E-Mail
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default InclusionApp;
