/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';

const IconSchool = ({
    strokeWidth = '2',
    height = '32',
    width = '32',
    className = '',
}) => (
    <svg strokeWidth={strokeWidth} className={className} width={width} height={height} viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.75 9.20317H5.8125M4.75 13.4532H5.8125M16.4375 13.4532H17.5M16.4375 9.20317H17.5M10.5938 9.20317H11.6562M9 19.2969V15.0469C9 13.8733 9.95136 12.9219 11.125 12.9219C12.2986 12.9219 13.25 13.8733 13.25 15.0469V19.2969M16.4375 4.95318L18.1121 5.37183C19.033 5.60204 19.4934 5.71715 19.8362 5.97384C20.1386 6.20032 20.3752 6.50336 20.5216 6.85173C20.6875 7.24656 20.6875 7.72119 20.6875 8.67032V15.8969C20.6875 17.087 20.6875 17.6821 20.4559 18.1367C20.2522 18.5365 19.9271 18.8616 19.5273 19.0653C19.0727 19.2969 18.4776 19.2969 17.2875 19.2969H4.9625C3.77239 19.2969 3.17733 19.2969 2.72277 19.0653C2.32292 18.8616 1.99784 18.5365 1.79411 18.1367C1.5625 17.6821 1.5625 17.087 1.5625 15.8969V8.67032C1.5625 7.72119 1.5625 7.24656 1.72839 6.85173C1.87475 6.50336 2.11136 6.20032 2.41382 5.97384C2.75663 5.71715 3.21705 5.60204 4.13787 5.37183L5.8125 4.95318L8.85052 2.21896C9.65439 1.49543 10.0564 1.13367 10.511 0.996459C10.9114 0.875611 11.3386 0.875611 11.739 0.996459C12.1936 1.13367 12.5956 1.49543 13.3995 2.21896L16.4375 4.95318Z" stroke="white" strokeWidth="1.59375" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default IconSchool;

IconSchool.propTypes = {
    strokeWidth: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    className: PropTypes.string,
};
