import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from './Resources/locales/de.json';

i18n.use(initReactI18next).init({
    fallbackLng: 'de',
    lng: 'de',
    resources: {
        de,
    },
    returnObjects: true,
    debug: false,
});

export default i18n;
