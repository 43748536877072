import React from 'react';
import i18n from '../../i18n';
import IconContactPerson from '../../assets/images/icons/IconContactPerson';
/* eslint-disable */

/**
 *
 * @param {object} markerContent
 * @param {object} styles
 * @returns {JSX.Element}
 */
const institutionContactPerson = (markerContent, styles) => {
    const homepage = markerContent?.homepage;
    const isClearing = markerContent?.subCategoryIds?.includes('institutionClearing') || false;
    const contactPersons = [
        {
            firstname: markerContent?.firstname[0],
            lastname: markerContent?.lastname[0],
            email: markerContent?.email[0],
            phoneCode: markerContent?.phoneCode[0],
            phoneNumber: markerContent.phoneNumber[0],
        },
        {
            firstname: markerContent?.firstname[1],
            lastname: markerContent?.lastname[1],
            email: markerContent?.email[1],
            phoneCode: markerContent?.phoneCode[1],
            phoneNumber: markerContent?.phoneNumber[1],
        },
        {
            firstname: markerContent?.firstname[2],
            lastname: markerContent?.lastname[2],
            email: markerContent?.email[2],
            phoneCode: markerContent?.phoneCode[2],
            phoneNumber: markerContent?.phoneNumber[2],
        },
    ];

    return (
        <div className={styles.infoRow}>
            <div
                className={styles.contact}
            >
                <div>
                    <div className={styles.label}>
                        {i18n.t('location.contactPerson')}
                    </div>
                    {contactPersons.map((contactPerson, index) => {
                        if (!contactPerson.lastname) {
                            return <React.Fragment key={index} />;
                        }

                        return (
                            <div key={index} className={styles.details}>
                                <IconContactPerson
                                    width="24px"
                                    height="24px"
                                    strokeWidth="2.5"
                                />
                                <div>
                                    {contactPerson?.firstname}
                                    {' '}
                                    {contactPerson?.lastname}
                                    <br />
                                    {contactPerson?.email}
                                    <br />
                                    {contactPerson?.phoneCode}
                                    {' '}
                                    {contactPerson?.phoneNumber}
                                    <br />
                                    {homepage}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );

};

/**
 *
 * @param {object} markerContent
 * @param {object} styles
 * @returns {JSX.Element}
 */
const institutionAddress = (markerContent, styles) => {
    return (
        <div className={styles.infoRow}>
            <div>
                <div className={styles.label}>
                    {i18n.t('location.address')}
                </div>
                {markerContent?.streetName
                    && (
                        <div className={styles.details}>
                            <address>
                                {`${markerContent?.streetName} ${markerContent?.houseNumber}`}
                                <br />
                                {`${markerContent?.postcode} ${markerContent?.community}`}
                                <br />
                                {`${markerContent?.communityPart}`}
                            </address>
                        </div>
                    )}
            </div>
        </div>

    );

};

export {
    institutionContactPerson,
    institutionAddress,
}
