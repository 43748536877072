/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';

const IconShadow = ({
    strokeWidth = '2',
    height = '13',
    width = '47',
    className = '',
}) => (
    <svg strokeWidth={strokeWidth} className={`${className}`} width={width} height={height} viewBox="0 0 47 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.641" filter="url(#filter0_d_46_18814)">
            <path d="M23.2974 6C33.7382 6 42.2022 4.66358 42.2022 3.01503C42.2022 1.36648 33.7382 0.0300598 23.2974 0.0300598C12.8565 0.0300598 4.39258 1.36648 4.39258 3.01503C4.39258 4.66358 12.8565 6 23.2974 6Z" fill="#313131" fillOpacity="0.5" shapeRendering="crispEdges" />
        </g>
        <defs>
            <filter id="filter0_d_46_18814" x="0.392578" y="0.0300598" width="45.8096" height="13.9699" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_46_18814" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_46_18814" result="shape" />
            </filter>
        </defs>
    </svg>

);

export default IconShadow;

IconShadow.propTypes = {
    strokeWidth: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    className: PropTypes.string,
};
