/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';

const IconKoopv = ({
    height = '30',
    width = '30',
    className = '',
}) => (
    <svg className={className} width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
        <defs>
            <style>{`.cls-1 {fill: #000!important;stroke: #000;stroke-width: 4px;}`}</style>
        </defs>
        <path className="cls-1" d="m121.47,128.59c-16.67,0-30.23-13.56-30.23-30.23s13.56-30.23,30.23-30.23,30.23,13.56,30.23,30.23-13.56,30.23-30.23,30.23Zm0-55.46c-13.91,0-25.23,11.32-25.23,25.23s11.32,25.23,25.23,25.23,25.23-11.32,25.23-25.23-11.32-25.23-25.23-25.23Z"/>
        <path className="cls-1" d="m171.66,193.31c-1.38,0-2.5-1.12-2.5-2.5,0-26.29-21.39-47.68-47.68-47.68s-47.68,21.39-47.68,47.68c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5c0-29.05,23.63-52.68,52.68-52.68s52.68,23.63,52.68,52.68c0,1.38-1.12,2.5-2.5,2.5Z"/>
        <path className="cls-1" d="m44.92,97.47c-14,0-25.39-11.39-25.39-25.39s11.39-25.39,25.39-25.39,25.39,11.39,25.39,25.39-11.39,25.39-25.39,25.39Zm0-45.78c-11.24,0-20.39,9.15-20.39,20.39s9.15,20.39,20.39,20.39,20.39-9.15,20.39-20.39-9.15-20.39-20.39-20.39Z"/>
        <path className="cls-1" d="m3.49,150.89c-1.38,0-2.5-1.12-2.5-2.5,0-24.22,19.7-43.92,43.92-43.92,15.35,0,29.79,8.19,37.7,21.37.71,1.18.33,2.72-.86,3.43-1.19.71-2.72.33-3.43-.86-7.11-11.86-19.6-18.94-33.41-18.94-21.46,0-38.92,17.46-38.92,38.92,0,1.38-1.12,2.5-2.5,2.5Z"/>
        <path className="cls-1" d="m195.08,97.47c-14,0-25.39-11.39-25.39-25.39s11.39-25.39,25.39-25.39,25.39,11.39,25.39,25.39-11.39,25.39-25.39,25.39Zm0-45.78c-11.24,0-20.39,9.15-20.39,20.39s9.15,20.39,20.39,20.39,20.39-9.15,20.39-20.39-9.15-20.39-20.39-20.39Z"/>
        <path className="cls-1" d="m236.51,150.89c-1.38,0-2.5-1.12-2.5-2.5,0-21.46-17.46-38.92-38.92-38.92-13.81,0-26.3,7.08-33.41,18.94-.71,1.18-2.25,1.57-3.43.86-1.18-.71-1.57-2.25-.86-3.43,7.9-13.18,22.35-21.37,37.7-21.37,24.22,0,43.92,19.7,43.92,43.92,0,1.38-1.12,2.5-2.5,2.5Z"/>
    </svg>
);

export default IconKoopv;

IconKoopv.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
    className: PropTypes.string,
};
