import * as React from 'react';
import PropTypes from 'prop-types';

const IconChevronDown = ({
    strokeWidth = '2',
    height = '24',
    width = '24',
    className = '',
    ...other
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`feather feather-chevron-down ${className}`}
        {...other}
    >
        <polyline points="6 9 12 15 18 9" />
    </svg>
);

export default IconChevronDown;

IconChevronDown.propTypes = {
    strokeWidth: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    className: PropTypes.string,
};
