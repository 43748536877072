/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';

const IconSchuleBeratungsstelle = ({
    strokeWidth = '8',
    height = '32',
    width = '32',
    className = '',
}) => (
    <svg strokeWidth={strokeWidth} className={className} width={width} height={height} viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <style>{`.cls-001 {fill: #fff!important;stroke: #fff;stroke-width: 4px;}`}</style>
        </defs>
        <path className="cls-001" d="m71.13,177.72v-37.31c0-11.14,9.07-20.21,20.21-20.21,6.5,0,12.3,3.09,15.99,7.88.93-1.44,1.92-2.83,2.97-4.18-4.6-5.3-11.4-8.65-18.96-8.65-13.87,0-25.16,11.29-25.16,25.16v37.31H7.43c-1.37,0-2.48-1.11-2.48-2.48V61.39c0-1.37,1.11-2.48,2.48-2.48h13.2c4.91,0,9.75-1.65,13.64-4.66L89.72,6.63c.94-.8,2.3-.8,3.23,0l55.36,47.54.1.08c3.89,3.01,8.73,4.66,13.64,4.66h13.2c1.37,0,2.48,1.11,2.48,2.48v39.87c1.68.46,3.33.98,4.95,1.56v-41.44c0-4.1-3.33-7.43-7.43-7.43h-13.2c-3.8,0-7.54-1.28-10.56-3.58L96.18,2.87c-2.8-2.41-6.87-2.41-9.68,0L31.19,50.38c-3.02,2.31-6.76,3.58-10.56,3.58H7.43c-4.1,0-7.43,3.33-7.43,7.43v113.86c0,4.1,3.33,7.43,7.43,7.43h92.74c-.53-1.62-1-3.28-1.4-4.95h-27.64Z"/>
        <path className="cls-001" d="m52.91,81.19c0-4.09-3.33-7.43-7.43-7.43h-19.84c-4.09,0-7.43,3.33-7.43,7.43v9.9c0,4.09,3.33,7.43,7.43,7.43h19.84c4.09,0,7.43-3.33,7.43-7.43v-9.9Zm-4.95,9.9c0,1.37-1.11,2.48-2.48,2.48h-19.84c-1.37,0-2.48-1.11-2.48-2.48v-9.9c0-1.37,1.11-2.48,2.48-2.48h19.84c1.37,0,2.48,1.11,2.48,2.48v9.9Z"/>
        <path className="cls-001" d="m137.19,73.76c-4.1,0-7.43,3.33-7.43,7.43v9.9c0,4.1,3.33,7.43,7.43,7.43h19.84c4.1,0,7.43-3.33,7.43-7.43v-9.9c0-4.1-3.33-7.43-7.43-7.43h-19.84Zm22.32,7.43v9.9c0,1.37-1.11,2.48-2.48,2.48h-19.84c-1.37,0-2.48-1.11-2.48-2.48v-9.9c0-1.37,1.11-2.48,2.48-2.48h19.84c1.37,0,2.48,1.11,2.48,2.48Z"/>
        <path className="cls-001" d="m45.49,123.27h-19.84c-4.09,0-7.43,3.33-7.43,7.43v9.9c0,4.09,3.33,7.43,7.43,7.43h19.84c4.09,0,7.43-3.33,7.43-7.43v-9.9c0-4.09-3.33-7.43-7.43-7.43Zm2.48,17.33c0,1.37-1.11,2.48-2.48,2.48h-19.84c-1.37,0-2.48-1.11-2.48-2.48v-9.9c0-1.37,1.11-2.48,2.48-2.48h19.84c1.37,0,2.48,1.11,2.48,2.48v9.9Z"/>
        <path className="cls-001" d="m81.42,73.76c-4.1,0-7.43,3.33-7.43,7.43v9.9c0,4.1,3.33,7.43,7.43,7.43h19.84c4.1,0,7.43-3.33,7.43-7.43v-9.9c0-4.1-3.33-7.43-7.43-7.43h-19.84Zm22.32,7.43v9.9c0,1.37-1.11,2.48-2.48,2.48h-19.84c-1.37,0-2.48-1.11-2.48-2.48v-9.9c0-1.37,1.11-2.48,2.48-2.48h19.84c1.37,0,2.48,1.11,2.48,2.48Z"/>
        <path className="cls-001" d="m198.88,162.15h0c0-6.93-1.86-13.57-5.33-19.36-1.58-2.63-3.49-5.09-5.72-7.31-7.12-7.13-16.6-11.05-26.68-11.05s-19.55,3.93-26.68,11.05c-1.78,1.78-3.36,3.71-4.73,5.76-4.11,6.14-6.32,13.36-6.32,20.92h0c0,2.1.18,4.17.51,6.2,1.27,7.72,4.9,14.83,10.54,20.47,7.12,7.13,16.6,11.05,26.68,11.05,3.15,0,6.24-.38,9.22-1.13,6.55-1.64,12.56-5.02,17.46-9.92,7.13-7.12,11.05-16.6,11.05-26.68h0Zm-37.73,32.78c-2.19,0-4.34-.21-6.44-.63-6.3-1.25-12.09-4.33-16.73-8.97-1.55-1.55-2.92-3.22-4.11-5-3.57-5.34-5.49-11.61-5.49-18.17,0-7.66,2.61-14.92,7.41-20.76.69-.83,1.42-1.64,2.19-2.41,6.19-6.19,14.42-9.6,23.17-9.6s16.99,3.41,23.18,9.61c2.32,2.32,4.25,4.93,5.76,7.74,2.51,4.69,3.84,9.96,3.84,15.43,0,4.2-.79,8.27-2.29,12.06-1.63,4.11-4.09,7.89-7.31,11.12-6.19,6.19-14.42,9.6-23.18,9.6Z"/>
        <path className="cls-001" d="m236.6,220.28l-28.96-28.09c14.17-21.84,11.23-50.62-7.35-69.19-10.46-10.46-24.36-16.22-39.14-16.22s-28.68,5.76-39.14,16.22c-10.46,10.46-16.22,24.36-16.22,39.14s5.76,28.68,16.22,39.14c10.75,10.75,24.9,16.27,39.15,16.27,10.44,0,20.94-2.97,30.19-9.01l28.96,28.09c2.25,2.24,5.2,3.36,8.16,3.36s5.91-1.12,8.16-3.36h0c4.49-4.5,4.49-11.82-.03-16.35Zm-111.09-22.5c-9.52-9.52-14.77-22.18-14.77-35.64s5.24-26.12,14.77-35.64c9.52-9.52,22.18-14.77,35.64-14.77s26.12,5.24,35.64,14.77c17.38,17.38,19.74,44.55,5.62,64.6-1.68,2.38-3.57,4.63-5.62,6.68-.51.51-1.04,1.02-1.57,1.5-1.6,1.47-3.28,2.83-5.02,4.06-20.07,14.19-47.28,11.85-64.69-5.56Zm107.62,35.34c-2.57,2.56-6.75,2.56-9.35-.02l-28.34-27.5c.14-.11.28-.24.42-.35.57-.46,1.12-.92,1.67-1.4.22-.19.43-.38.65-.57.72-.65,1.43-1.31,2.12-2,.7-.7,1.38-1.43,2.04-2.17.19-.21.37-.42.56-.63.52-.6,1.03-1.21,1.53-1.84.1-.13.21-.24.31-.37l28.39,27.53c2.56,2.57,2.56,6.75,0,9.32Z"/>
    </svg>
);

IconSchuleBeratungsstelle.propTypes = {
    strokeWidth: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    className: PropTypes.string,
};

export default IconSchuleBeratungsstelle;
