import React from 'react';
import styles from './Spinner.module.scss';

const Spinner = () => (
    <div className={styles.wrapper}>
        <div className={styles.loader} />
    </div>
);

Spinner.propTypes = {};

export default Spinner;
