/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';

const IconInstitutionSehbehinderung = ({
    strokeWidth = '8',
    height = '32',
    width = '32',
    className = '',
    stroke = '#fff',
}) => (
    <svg strokeWidth={strokeWidth} className={className} width={width} height={height} viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <style>{`.cls-002 {fill: ${stroke}!important;stroke: ${stroke};stroke-width: 4px;}`}</style>
        </defs>
      <path className="cls-002" d="m233.08,106.07c-12.7-12.1-58.32-51.57-112.58-51.57S20.61,93.97,7.92,106.07c-1.5,1.43-2.32,3.36-2.32,5.43s.82,4,2.32,5.43c12.53,11.94,57.14,50.55,110.48,51.55h.14c.49.01.97.02,1.46.02h.5c5.34,0,10.59-.38,15.74-1.09-.05-.42-.08-.84-.11-1.26-.09-1.2-.13-2.42-.13-3.65v-.1c0-.49.01-.97.03-1.46-4.98,1.64-10.29,2.54-15.81,2.56-.52,0-1.04-.01-1.55-.02h-.09c-27.74-.75-50.08-23.55-50.08-51.48s23.1-51.5,51.5-51.5,51.5,23.1,51.5,51.5c0,.51-.01,1.03-.03,1.54,1.64-.54,3.32-.99,5.03-1.36v-.18c0-21.04-11.57-39.44-28.68-49.16,15.62,3.75,29.97,10.24,42.27,17.34,19.19,11.08,33.37,23.62,39.54,29.51.5.48.77,1.12.77,1.81s-.27,1.33-.77,1.81c-2.29,2.19-5.69,5.29-10.04,8.9,1.32,1.05,2.58,2.17,3.79,3.34,4.18-3.48,7.45-6.48,9.7-8.62,1.5-1.43,2.32-3.36,2.32-5.43s-.82-4-2.32-5.43Zm-169.58,5.93c0,19.57,10.01,36.85,25.17,46.99-37.86-10.5-67.55-36.39-77.3-45.68-.5-.48-.77-1.12-.77-1.81s.27-1.33.77-1.81c9.98-9.51,40.89-36.44,80.06-46.42-16.7,9.83-27.93,28-27.93,48.73Z"/>
      <path className="cls-002" d="m120,79.5c-17.92,0-32.5,14.58-32.5,32.5s14.58,32.5,32.5,32.5,32.5-14.58,32.5-32.5-14.58-32.5-32.5-32.5Zm0,60c-15.16,0-27.5-12.34-27.5-27.5s12.34-27.5,27.5-27.5,27.5,12.34,27.5,27.5-12.34,27.5-27.5,27.5Z"/>
      <path className="cls-002" d="m187.5,117.5c-24.81,0-45,20.19-45,45s20.19,45,45,45,45-20.19,45-45-20.19-45-45-45Zm0,85c-22.06,0-40-17.94-40-40s17.94-40,40-40,40,17.94,40,40-17.94,40-40,40Z"/>
      <path className="cls-002" d="m212.25,137.75c-.98-.98-2.56-.98-3.54,0l-21.21,21.21-21.21-21.21c-.98-.98-2.56-.98-3.54,0s-.98,2.56,0,3.54l21.21,21.21-21.21,21.21c-.98.98-.98,2.56,0,3.54.49.49,1.13.73,1.77.73s1.28-.24,1.77-.73l21.21-21.21,21.21,21.21c.49.49,1.13.73,1.77.73s1.28-.24,1.77-.73c.98-.98.98-2.56,0-3.54l-21.21-21.21,21.21-21.21c.98-.98.98-2.56,0-3.54Z"/>
    </svg>
);


IconInstitutionSehbehinderung.propTypes = {
    strokeWidth: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    className: PropTypes.string,
    stroke: PropTypes.string,
};

export default IconInstitutionSehbehinderung;
