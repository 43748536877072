import React from 'react';
import i18n from '../../i18n';
/* eslint-disable */

/**
 *
 * @param {object} markerContent
 * @param {object} styles
 * @returns {JSX.Element}
 */
const schoolCategoryKey = (markerContent, styles) => {
    const schoolCategory = (markerContent?.categoryLabel || '').replace(/§/, '§ ');

    return (
        <div className={styles.infoRow}>
            <div>
                <div className={styles.label}>
                    {i18n.t('location.categoryLabel')}
                </div>
                {schoolCategory !== ''
                    && (
                        <div className={styles.details}>
                            {schoolCategory}
                        </div>
                    )}
            </div>
        </div>
    );

};

/**
 *
 * @param {object} markerContent
 * @param {object} styles
 * @returns {JSX.Element}
 */
const schoolType = (markerContent, styles) => {
    const schoolType = (markerContent?.typeLabel || '').replace(/§/, '§ ');

    return (
        <div className={styles.infoRow}>
            <div>
                <div className={styles.label}>
                    {i18n.t('location.typeLabel')}
                </div>
                {schoolType !== ''
                    && (
                        <div className={styles.details}>
                            {schoolType}
                        </div>
                    )}
            </div>
        </div>
    );

};

/**
 *
 * @param {object} markerContent
 * @param {object} styles
 * @returns {JSX.Element}
 */
const schoolAddress = (markerContent, styles) => {
    return (
        <div className={styles.infoRow}>
            <div>
                <div className={styles.label}>
                    {i18n.t('location.address')}
                </div>
                {markerContent?.streetName
                    && (
                        <div className={styles.details}>
                            <address>
                                {`${markerContent?.streetName} ${markerContent?.houseNumber}`}
                                <br />
                                {`${markerContent?.postcode} ${markerContent?.community}`}
                                <br />
                                {`${markerContent?.communityPart}`}
                            </address>
                        </div>
                    )}
            </div>
        </div>
    );

};

/**
 *
 * @param {object} markerContent
 * @param {object} styles
 * @returns {JSX.Element}
 */
const schoolKoopv = (markerContent, styles) => {
    const { koopvInformations } = markerContent;

    if (!koopvInformations?.title) {
      return  <></>;
    }

    return (
        <div className={styles.infoRow}>

            <div
                className={styles.contact}
            >
                <div>
                    <div className={styles.label}>
                        {i18n.t('location.koopv')}
                    </div>
                    {koopvInformations?.title
                        && (
                            <div className={styles.details}>
                              {koopvInformations?.title}
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
};

/**
 *
 * @param {object} markerContent
 * @param {object} styles
 * @returns {JSX.Element}
 */
const schoolHomepage = (markerContent, styles) => {
    return (
        <div className={styles.infoRow}>

            <div
                className={styles.contact}
            >
                <div>
                    <div className={styles.label}>
                        {i18n.t('location.homepage')}
                    </div>
                    {markerContent?.homepage
                        && (
                            <div className={styles.details}>
                                <a
                                    rel="noreferrer"
                                    href={markerContent?.homepage}
                                    target="_blank"
                                >
                                    {markerContent?.homepage}
                                </a>
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
};


export {
    schoolCategoryKey,
    schoolType,
    schoolAddress,
    schoolHomepage,
    schoolKoopv,
}
