import React from 'react';
import PropTypes from 'prop-types';
import styles from './CustomMarker.module.scss';

const DefaultMarker = ({
    className = '',
    height = '60.645',
    width = '48.627',
    fillcolor,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 48.627 60.645"
        className={[className, styles.markerSvg].join(' ')}
    >
        <g id="pin-bildung-erleben" transform="translate(-1228 -564)">
            <g
                id="Gruppe_242"
                data-name="Gruppe 242"
                transform="translate(295.42 -799.678)"
            >
                <path
                    id="Pfad_86"
                    data-name="Pfad 86"
                    d="M24.313-102A24.286,24.286,0,0,1,48.627-77.742,24.279,24.279,0,0,1,29.789-54.1L24.313-41.355,19.5-53.96A24.28,24.28,0,0,1,0-77.742,24.286,24.286,0,0,1,24.313-102Z"
                    transform="translate(932.58 1465.678)"
                    fill={fillcolor}
                    fillRule="evenodd"
                />
            </g>
        </g>
    </svg>
);

DefaultMarker.propTypes = {
    className: PropTypes.string,
    fillcolor: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default DefaultMarker;
