/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';

const IconInstitutionLandesinkluClearing = ({
                                              height = '36',
                                              width = '36',
                                              className = '',
                                            }) => (
  <svg
    className={className}
    height={height}
    width={width}
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 140 110"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
  ><defs
    id="defs189" />
    <style
      type="text/css"
      id="style2">
        {`.st0{fill:#313131;}
          .st1{fill:#7907A9;}
          .st2{fill:#008AC4;}
          .st3{fill:#EC520B;}
          .st4{fill:#FFE251;}
          .st5{fill:#BA0631;}
          .st6{fill:#00654C;}
          .st7{fill:#56BE06;}`}
    </style>
    <g
      id="g184">
	<path
    className="st1"
    d="m 26.968686,102.03007 c 0,2.6 -2.1,4.8 -4.8,4.8 -2.6,0 -4.8,-2.1 -4.8,-4.8 0,-2.6 2.1,-4.8 4.8,-4.8 2.7,0.1 4.8,2.2 4.8,4.8 z"
    id="path12"/>
      <path
        className="st2"
        d="m 27.968686,91.63007 c 0,2.9 -2.3,5.2 -5.2,5.2 -2.9,0 -5.2,-2.3 -5.2,-5.2 0,-2.9 2.3,-5.2 5.2,-5.2 2.9,-0.1 5.2,2.3 5.2,5.2 z"
        id="path14"/>
      <path
        className="st3"
        d="m 41.968686,82.73007 c 0,2.8 -2.2,5 -5,5 -2.8,0 -5,-2.2 -5,-5 0,-2.8 2.2,-5 5,-5 2.8,0 5,2.3 5,5 z"
        id="path16"/>
      <path
        className="st4"
        d="m 14.068686,81.23007 c 0,3.1 -2.5,5.6 -5.6000002,5.6 -3.1,0 -5.6,-2.5 -5.6,-5.6 0,-3.1 2.5,-5.6 5.6,-5.6 3.1000002,0 5.6000002,2.5 5.6000002,5.6 z"
        id="path18"/>
      <path
        className="st2"
        d="m 119.96869,37.83007 c 0,3.1 -2.5,5.6 -5.6,5.6 -3.1,0 -5.6,-2.5 -5.6,-5.6 0,-3.1 2.5,-5.6 5.6,-5.6 3.1,0 5.6,2.5 5.6,5.6 z"
        id="path20"/>
      <path
        className="st5"
        d="m 99.468686,54.03007 c 0,3.1 -2.5,5.6 -5.6,5.6 -3.1,0 -5.6,-2.5 -5.6,-5.6 0,-3.1 2.5,-5.6 5.6,-5.6 3.1,0 5.6,2.5 5.6,5.6 z"
        id="path22"/>
      <path
        className="st4"
        d="m 89.968686,61.13007 c 0,2.4 -1.9,4.3 -4.3,4.3 -2.4,0 -4.3,-1.9 -4.3,-4.3 0,-2.4 1.9,-4.3 4.3,-4.3 2.4,0 4.3,1.9 4.3,4.3 z"
        id="path24"/>
      <path
        className="st6"
        d="m 81.068686,58.63007 c 0,1.7 -1.4,3.1 -3.1,3.1 -1.7,0 -3.1,-1.4 -3.1,-3.1 0,-1.7 1.4,-3.1 3.1,-3.1 1.7,0 3.1,1.4 3.1,3.1 z"
        id="path26"/>
      <path
        className="st1"
        d="m 110.16869,50.13007 c 0,2.1 -1.7,3.7 -3.7,3.7 -2.1,0 -3.7,-1.7 -3.7,-3.7 0,-2.1 1.7,-3.7 3.7,-3.7 2,0 3.7,1.7 3.7,3.7 z"
        id="path28"/>
      <path
        className="st3"
        d="m 80.268686,64.73007 c 0,1.2 -1,2.1 -2.1,2.1 -1.2,0 -2.1,-1 -2.1,-2.1 0,-1.2 1,-2.1 2.1,-2.1 1.1,-0.1 2.1,0.9 2.1,2.1 z"
        id="path30"/>
      <path
        className="st2"
        d="m 84.468686,54.23007 c 0,1.2 -1,2.1 -2.1,2.1 -1.2,0 -2.1,-1 -2.1,-2.1 0,-1.2 1,-2.1 2.1,-2.1 1.1,0 2.1,0.9 2.1,2.1 z"
        id="path32"/>
      <path
        className="st2"
        d="m 91.268686,46.93007 c 0,0.8 -0.6,1.4 -1.4,1.4 -0.8,0 -1.4,-0.6 -1.4,-1.4 0,-0.8 0.6,-1.4 1.4,-1.4 0.8,0 1.4,0.6 1.4,1.4 z"
        id="path34"/>
      <path
        className="st1"
        d="m 100.66869,48.53007 c 0,0.8 -0.6,1.4 -1.400004,1.4 -0.8,0 -1.4,-0.6 -1.4,-1.4 0,-0.8 0.6,-1.4 1.4,-1.4 0.800004,0 1.400004,0.6 1.400004,1.4 z"
        id="path36"/>
      <path
        className="st3"
        d="m 95.968686,45.83007 c 0,1.1 -0.9,2 -2,2 -1.1,0 -2,-0.9 -2,-2 0,-1.1 0.9,-2 2,-2 1.1,0.1 2,1 2,2 z"
        id="path38"/>
      <path
        className="st4"
        d="m 104.86869,44.53007 c 0,1.1 -0.9,2 -2,2 -1.1,0 -2,-0.9 -2,-2 0,-1.1 0.9,-2 2,-2 1.1,0 2,0.9 2,2 z"
        id="path40"/>
      <path
        className="st7"
        d="m 88.168686,45.23007 c 0,1.2 -1,2.1 -2.1,2.1 -1.2,0 -2.1,-1 -2.1,-2.1 0,-1.2 1,-2.1 2.1,-2.1 1.1,0 2.1,0.9 2.1,2.1 z"
        id="path42"/>
      <path
        className="st7"
        d="m 82.368686,28.93007 c 0,1.2 -1,2.1 -2.1,2.1 -1.2,0 -2.1,-1 -2.1,-2.1 0,-1.2 1,-2.1 2.1,-2.1 1.1,0 2.1,1 2.1,2.1 z"
        id="path44"/>
      <path
        className="st2"
        d="m 56.268686,81.03007 c 0,1.2 -1,2.1 -2.1,2.1 -1.2,0 -2.1,-1 -2.1,-2.1 0,-1.2 1,-2.1 2.1,-2.1 1.1,0 2.1,0.9 2.1,2.1 z"
        id="path46"/>
      <path
        className="st4"
        d="m 38.968686,52.63007 c 0,1.2 -1,2.1 -2.1,2.1 -1.2,0 -2.1,-1 -2.1,-2.1 0,-1.2 1,-2.1 2.1,-2.1 1.1,-0.1 2.1,0.9 2.1,2.1 z"
        id="path48"/>
      <path
        className="st4"
        d="m 85.068686,37.53007 c 0,3.1 -2.5,5.6 -5.6,5.6 -3.1,0 -5.6,-2.5 -5.6,-5.6 0,-3.1 2.5,-5.6 5.6,-5.6 3.1,0 5.6,2.5 5.6,5.6 z"
        id="path50"/>
      <path
        className="st7"
        d="m 81.668686,48.13007 c 0,2.2 -1.8,4 -4,4 -2.2,0 -4,-1.8 -4,-4 0,-2.2 1.8,-4 4,-4 2.2,-0.1 4,1.8 4,4 z"
        id="path52"/>
      <path
        className="st1"
        d="m 135.26869,39.63007 c 0,3.8 -3.1,6.9 -6.9,6.9 -3.8,0 -6.9,-3.1 -6.9,-6.9 0,-3.8 3.1,-6.9 6.9,-6.9 3.8,0 6.9,3.1 6.9,6.9 z"
        id="path54"/>
      <path
        className="st3"
        d="m 39.668686,44.73007 c 0,3.1 -2.5,5.6 -5.6,5.6 -3.1,0 -5.6,-2.5 -5.6,-5.6 0,-3.1 2.5,-5.6 5.6,-5.6 3.1,0 5.6,2.5 5.6,5.6 z"
        id="path56"/>
      <path
        className="st2"
        d="m 65.768686,25.73007 c 0,3.1 -2.5,5.6 -5.6,5.6 -3.1,0 -5.6,-2.5 -5.6,-5.6 0,-3.1 2.5,-5.6 5.6,-5.6 3.1,0 5.6,2.5 5.6,5.6 z"
        id="path58"/>
      <path
        className="st7"
        d="m 55.168686,30.83007 c 0,1.9 -1.5,3.4 -3.4,3.4 -1.9,0 -3.4,-1.5 -3.4,-3.4 0,-1.9 1.5,-3.4 3.4,-3.4 1.9,0 3.4,1.5 3.4,3.4 z"
        id="path60"/>
      <path
        className="st3"
        d="m 71.068686,33.13007 c 0,1.9 -1.5,3.4 -3.4,3.4 -1.9,0 -3.4,-1.5 -3.4,-3.4 0,-1.9 1.5,-3.4 3.4,-3.4 1.9,0 3.4,1.5 3.4,3.4 z"
        id="path62"/>
      <path
        className="st5"
        d="m 62.668686,77.63007 c 0,1.9 -1.5,3.4 -3.4,3.4 -1.9,0 -3.4,-1.5 -3.4,-3.4 0,-1.9 1.5,-3.4 3.4,-3.4 1.9,0 3.4,1.5 3.4,3.4 z"
        id="path64"/>
      <path
        className="st7"
        d="m 71.368686,22.03007 c 0,1.6 -1.3,2.8 -2.8,2.8 -1.6,0 -2.8,-1.3 -2.8,-2.8 0,-1.5 1.3,-2.8 2.8,-2.8 1.5,-0.1 2.8,1.2 2.8,2.8 z"
        id="path66"/>
      <path
        className="st2"
        d="m 77.068686,29.13007 c 0,1.6 -1.3,2.8 -2.8,2.8 -1.6,0 -2.8,-1.3 -2.8,-2.8 0,-1.5 1.3,-2.8 2.8,-2.8 1.5,-0.1 2.8,1.2 2.8,2.8 z"
        id="path68"/>
      <path
        className="st7"
        d="m 89.768686,22.43007 c 0,1.6 -1.3,2.8 -2.8,2.8 -1.6,0 -2.8,-1.3 -2.8,-2.8 0,-1.5 1.3,-2.8 2.8,-2.8 1.5,0 2.8,1.3 2.8,2.8 z"
        id="path70"/>
      <path
        className="st7"
        d="m 34.468686,27.83007 c 0,1.9 -1.5,3.4 -3.4,3.4 -1.9,0 -3.4,-1.5 -3.4,-3.4 0,-1.9 1.5,-3.4 3.4,-3.4 1.8,-0.1 3.4,1.4 3.4,3.4 z"
        id="path72"/>
      <path
        className="st4"
        d="m 136.16869,29.13007 c 0,1.9 -1.5,3.4 -3.4,3.4 -1.9,0 -3.4,-1.5 -3.4,-3.4 0,-1.9 1.5,-3.4 3.4,-3.4 1.9,0 3.4,1.5 3.4,3.4 z"
        id="path74"/>
      <path
        className="st7"
        d="m 122.66869,46.73007 c 0,1.9 -1.5,3.4 -3.4,3.4 -1.9,0 -3.4,-1.5 -3.4,-3.4 0,-1.9 1.5,-3.4 3.4,-3.4 1.8,-0.1 3.4,1.5 3.4,3.4 z"
        id="path76"/>
      <path
        className="st6"
        d="m 131.26869,51.63007 c 0,2.5 -2,4.5 -4.5,4.5 -2.5,0 -4.5,-2 -4.5,-4.5 0,-2.5 2,-4.5 4.5,-4.5 2.5,0 4.5,2 4.5,4.5 z"
        id="path78"/>
      <path
        className="st7"
        d="m 49.468686,69.33007 c 0,1.9 -1.5,3.4 -3.4,3.4 -1.9,0 -3.4,-1.5 -3.4,-3.4 0,-1.9 1.5,-3.4 3.4,-3.4 1.9,0 3.4,1.5 3.4,3.4 z"
        id="path80"/>
      <path
        className="st7"
        d="m 30.768686,81.83007 c 0,1.9 -1.5,3.4 -3.4,3.4 -1.9,0 -3.4,-1.5 -3.4,-3.4 0,-1.9 1.5,-3.4 3.4,-3.4 1.9,-0.1 3.4,1.5 3.4,3.4 z"
        id="path82"/>
      <path
        className="st4"
        d="m 33.368686,89.13007 c 0,1.4 -1.1,2.5 -2.5,2.5 -1.4,0 -2.5,-1.1 -2.5,-2.5 0,-1.4 1.1,-2.5 2.5,-2.5 1.4,0 2.5,1.1 2.5,2.5 z"
        id="path84"/>
      <path
        className="st2"
        d="m 11.268686,89.83007 c 0,1.4 -1.1,2.5 -2.5000002,2.5 -1.4,0 -2.5,-1.1 -2.5,-2.5 0,-1.4 1.1,-2.5 2.5,-2.5 1.4000002,0 2.5000002,1.1 2.5000002,2.5 z"
        id="path86"/>
      <path
        className="st4"
        d="m 52.068686,86.23007 c 0,1.4 -1.1,2.5 -2.5,2.5 -1.4,0 -2.5,-1.1 -2.5,-2.5 0,-1.4 1.1,-2.5 2.5,-2.5 1.4,0.1 2.5,1.2 2.5,2.5 z"
        id="path88"/>
      <path
        className="st3"
        d="m 55.068686,73.33007 c 0,1.4 -1.1,2.5 -2.5,2.5 -1.4,0 -2.5,-1.1 -2.5,-2.5 0,-1.4 1.1,-2.5 2.5,-2.5 1.4,0 2.5,1.1 2.5,2.5 z"
        id="path90"/>
      <path
        className="st7"
        d="m 75.168686,65.93007 c 0,1.4 -1.1,2.5 -2.5,2.5 -1.4,0 -2.5,-1.1 -2.5,-2.5 0,-1.4 1.1,-2.5 2.5,-2.5 1.4,0 2.5,1.1 2.5,2.5 z"
        id="path92"/>
      <path
        className="st6"
        d="m 65.268686,70.73007 c 0,1.4 -1.1,2.5 -2.5,2.5 -1.4,0 -2.5,-1.1 -2.5,-2.5 0,-1.4 1.1,-2.5 2.5,-2.5 1.4,0 2.5,1.1 2.5,2.5 z"
        id="path94"/>
      <path
        className="st4"
        d="m 72.968686,71.73007 c 0,1.4 -1.1,2.5 -2.5,2.5 -1.4,0 -2.5,-1.1 -2.5,-2.5 0,-1.4 1.1,-2.5 2.5,-2.5 1.4,0 2.5,1.1 2.5,2.5 z"
        id="path96"/>
      <path
        className="st1"
        d="m 21.668686,77.33007 c 0,2 -1.7,3.7 -3.7,3.7 -2,0 -3.7,-1.7 -3.7,-3.7 0,-2 1.7,-3.7 3.7,-3.7 2,0 3.7,1.6 3.7,3.7 z"
        id="path98"/>
      <path
        className="st3"
        d="m 19.468686,84.93007 c 0,1.4 -1.1,2.5 -2.5,2.5 -1.4,0 -2.5,-1.1 -2.5,-2.5 0,-1.4 1.1,-2.5 2.5,-2.5 1.3,0 2.5,1.1 2.5,2.5 z"
        id="path100"/>
      <path
        className="st7"
        d="m 16.668686,92.73007 c 0,1.4 -1.1,2.5 -2.5,2.5 -1.4,0 -2.5,-1.1 -2.5,-2.5 0,-1.4 1.1,-2.5 2.5,-2.5 1.4,0 2.5,1.2 2.5,2.5 z"
        id="path102"/>
      <path
        className="st4"
        d="m 23.568686,82.23007 c 0,1.1 -0.9,1.9 -1.9,1.9 -1.1,0 -1.9,-0.9 -1.9,-1.9 0,-1.1 0.9,-1.9 1.9,-1.9 1,0 1.9,0.9 1.9,1.9 z"
        id="path104"/>
      <path
        className="st6"
        d="m 24.768686,77.93007 c 0,0.8 -0.6,1.4 -1.4,1.4 -0.8,0 -1.4,-0.6 -1.4,-1.4 0,-0.8 0.6,-1.4 1.4,-1.4 0.8,0 1.4,0.6 1.4,1.4 z"
        id="path106"/>
      <path
        className="st5"
        d="m 14.368686,88.03007 c 0,0.8 -0.6,1.4 -1.4,1.4 -0.8,0 -1.4,-0.6 -1.4,-1.4 0,-0.8 0.6,-1.4 1.4,-1.4 0.8,-0.1 1.4,0.6 1.4,1.4 z"
        id="path108"/>
      <path
        className="st5"
        d="m 37.068686,35.43007 c 0,1.4 -1.1,2.5 -2.5,2.5 -1.4,0 -2.5,-1.1 -2.5,-2.5 0,-1.4 1.1,-2.5 2.5,-2.5 1.4,0 2.5,1.1 2.5,2.5 z"
        id="path110"/>
      <path
        className="st2"
        d="m 48.868686,35.23007 c 0,1.4 -1.1,2.5 -2.5,2.5 -1.4,0 -2.5,-1.2 -2.5,-2.5 0,-1.4 1.1,-2.5 2.5,-2.5 1.4,0 2.5,1.1 2.5,2.5 z"
        id="path112"/>
      <path
        className="st1"
        d="m 40.168686,30.23007 c 0,1.4 -1.1,2.5 -2.5,2.5 -1.4,0 -2.5,-1.1 -2.5,-2.5 0,-1.4 1.1,-2.5 2.5,-2.5 1.3,0 2.5,1.1 2.5,2.5 z"
        id="path114"/>
      <path
        className="st6"
        d="m 43.968686,39.13007 c 0,1.4 -1.1,2.5 -2.5,2.5 -1.4,0 -2.5,-1.1 -2.5,-2.5 0,-1.4 1.1,-2.5 2.5,-2.5 1.3,0 2.5,1.1 2.5,2.5 z"
        id="path116"/>
      <path
        className="st4"
        d="m 42.468686,33.93007 c 0,0.8 -0.6,1.4 -1.4,1.4 -0.8,0 -1.4,-0.6 -1.4,-1.4 0,-0.8 0.6,-1.4 1.4,-1.4 0.8,0 1.4,0.6 1.4,1.4 z"
        id="path118"/>
      <path
        className="st5"
        d="m 46.568686,30.33007 c 0,0.8 -0.6,1.4 -1.4,1.4 -0.8,0 -1.4,-0.6 -1.4,-1.4 0,-0.8 0.6,-1.4 1.4,-1.4 0.7,-0.1 1.4,0.6 1.4,1.4 z"
        id="path120"/>
      <path
        className="st2"
        d="m 74.568686,60.73007 c 0,0.8 -0.6,1.4 -1.4,1.4 -0.8,0 -1.4,-0.6 -1.4,-1.4 0,-0.8 0.6,-1.4 1.4,-1.4 0.7,-0.1 1.4,0.6 1.4,1.4 z"
        id="path122"/>
      <path
        className="st2"
        d="m 73.168686,37.93007 c 0,0.8 -0.6,1.4 -1.4,1.4 -0.8,0 -1.4,-0.6 -1.4,-1.4 0,-0.8 0.6,-1.4 1.4,-1.4 0.8,-0.1 1.4,0.6 1.4,1.4 z"
        id="path124"/>
      <path
        className="st7"
        d="m 69.968686,27.73007 c 0,0.8 -0.6,1.4 -1.4,1.4 -0.8,0 -1.4,-0.6 -1.4,-1.4 0,-0.8 0.6,-1.4 1.4,-1.4 0.8,-0.1 1.4,0.6 1.4,1.4 z"
        id="path126"/>
      <path
        className="st4"
        d="m 75.968686,23.83007 c 0,0.8 -0.6,1.4 -1.4,1.4 -0.8,0 -1.4,-0.6 -1.4,-1.4 0,-0.8 0.6,-1.4 1.4,-1.4 0.7,0 1.4,0.6 1.4,1.4 z"
        id="path128"/>
      <path
        className="st1"
        d="m 63.168686,33.43007 c 0,0.8 -0.6,1.4 -1.4,1.4 -0.8,0 -1.4,-0.6 -1.4,-1.4 0,-0.8 0.6,-1.4 1.4,-1.4 0.7,0 1.4,0.7 1.4,1.4 z"
        id="path130"/>
      <path
        className="st1"
        d="m 94.368686,20.03007 c 0,0.8 -0.6,1.4 -1.4,1.4 -0.8,0 -1.4,-0.6 -1.4,-1.4 0,-0.8 0.6,-1.4 1.4,-1.4 0.7,0 1.4,0.6 1.4,1.4 z"
        id="path132"/>
      <path
        className="st3"
        d="m 86.468686,27.53007 c 0,1 -0.8,1.8 -1.8,1.8 -1,0 -1.8,-0.8 -1.8,-1.8 0,-1 0.8,-1.8 1.8,-1.8 0.9,0 1.8,0.8 1.8,1.8 z"
        id="path134"/>
      <path
        className="st2"
        d="m 83.168686,24.23007 c 0,0.8 -0.6,1.4 -1.4,1.4 -0.8,0 -1.4,-0.6 -1.4,-1.4 0,-0.8 0.6,-1.4 1.4,-1.4 0.7,0 1.4,0.6 1.4,1.4 z"
        id="path136"/>
      <path
        className="st5"
        d="m 79.168686,25.03007 c 0,0.6 -0.5,1 -1,1 -0.6,0 -1,-0.5 -1,-1 0,-0.6 0.5,-1 1,-1 0.6,0 1,0.5 1,1 z"
        id="path138"/>
      <path
        className="st6"
        d="m 85.068686,31.33007 c 0,0.6 -0.5,1 -1,1 -0.6,0 -1,-0.5 -1,-1 0,-0.6 0.5,-1 1,-1 0.6,0 1,0.4 1,1 z"
        id="path140"/>
      <path
        className="st7"
        d="m 87.568686,52.83007 c 0,0.6 -0.5,1 -1,1 -0.6,0 -1,-0.5 -1,-1 0,-0.5 0.5,-1 1,-1 0.5,0 1,0.5 1,1 z"
        id="path142"/>
      <path
        className="st4"
        d="m 86.268686,49.43007 c 0,0.6 -0.5,1 -1,1 -0.6,0 -1,-0.5 -1,-1 0,-0.6 0.5,-1 1,-1 0.6,-0.1 1,0.4 1,1 z"
        id="path144"/>
      <path
        className="st6"
        d="m 91.268686,43.83007 c 0,0.6 -0.5,1 -1,1 -0.6,0 -1,-0.5 -1,-1 0,-0.6 0.5,-1 1,-1 0.5,0 1,0.4 1,1 z"
        id="path146"/>
      <path
        className="st7"
        d="m 99.368686,45.33007 c 0,0.6 -0.5,1 -1,1 -0.6,0 -1,-0.5 -1,-1 0,-0.5 0.5,-1 1,-1 0.5,0 1,0.4 1,1 z"
        id="path148"/>
      <path
        className="st7"
        d="m 108.36869,41.63007 c 0,0.8 -0.6,1.5 -1.3,1.5 -0.7,0 -1.5,-0.6 -1.5,-1.3 0,-0.8 0.6,-1.5 1.3,-1.5 0.7,-0.1 1.4,0.5 1.5,1.3 z"
        id="path150"/>
      <path
        className="st1"
        d="m 87.868686,40.83007 c 0,0.8 -0.6,1.5 -1.3,1.5 -0.8,0 -1.5,-0.6 -1.5,-1.3 0,-0.8 0.6,-1.5 1.3,-1.5 0.8,0 1.4,0.6 1.5,1.3 z"
        id="path152"/>
      <path
        className="st7"
        d="m 103.36869,53.93007 c 0,0.8 -0.6,1.5 -1.3,1.5 -0.8,0 -1.5,-0.6 -1.5,-1.3 0,-0.8 0.6,-1.5 1.3,-1.5 0.8,-0.1 1.5,0.5 1.5,1.3 z"
        id="path154"/>
      <path
        className="st4"
        d="m 111.06869,31.13007 c 0,0.8 -0.6,1.5 -1.3,1.5 -0.8,0 -1.5,-0.6 -1.5,-1.3 0,-0.8 0.6,-1.5 1.3,-1.5 0.8,-0.1 1.4,0.5 1.5,1.3 z"
        id="path156"/>
      <path
        className="st7"
        d="m 68.968686,67.83007 c 0,0.8 -0.6,1.4 -1.4,1.4 -0.8,0 -1.4,-0.6 -1.4,-1.4 0,-0.8 0.6,-1.4 1.4,-1.4 0.8,0 1.4,0.6 1.4,1.4 z"
        id="path158"/>
      <path
        className="st6"
        d="m 40.468686,56.23007 c 0,0.8 -0.6,1.4 -1.4,1.4 -0.8,0 -1.4,-0.6 -1.4,-1.4 0,-0.8 0.6,-1.4 1.4,-1.4 0.8,0 1.4,0.6 1.4,1.4 z"
        id="path160"/>
      <path
        className="st4"
        d="m 58.468686,70.93007 c 0,0.8 -0.6,1.4 -1.4,1.4 -0.8,0 -1.4,-0.6 -1.4,-1.4 0,-0.8 0.6,-1.4 1.4,-1.4 0.8,-0.1 1.4,0.6 1.4,1.4 z"
        id="path162"/>
      <path
        className="st2"
        d="m 67.368686,74.43007 c 0,0.8 -0.6,1.4 -1.4,1.4 -0.8,0 -1.4,-0.6 -1.4,-1.4 0,-0.8 0.6,-1.4 1.4,-1.4 0.8,-0.1 1.4,0.6 1.4,1.4 z"
        id="path164"/>
      <path
        className="st1"
        d="m 51.368686,79.23007 c 0,2.5 -2,4.5 -4.5,4.5 -2.5,0 -4.5,-2 -4.5,-4.5 0,-2.5 2,-4.5 4.5,-4.5 2.5,0 4.5,2 4.5,4.5 z"
        id="path166"/>
      <path
        className="st4"
        d="m 105.36869,15.33007 c 0,3.1 -2.5,5.6 -5.600004,5.6 -3.1,0 -5.6,-2.5 -5.6,-5.6 0,-3.1 2.5,-5.6000002 5.6,-5.6000002 3.100004,0 5.600004,2.5000002 5.600004,5.6000002 z"
        id="path168"/>
      <path
        className="st4"
        d="m 56.368686,15.23007 c 0,7 -5.7,12.8 -12.8,12.8 -7.1,0 -12.7,-5.8 -12.7,-12.8 0,-7.1000002 5.7,-12.8000002 12.7,-12.8000002 7,0 12.8,5.7 12.8,12.8000002 z"
        id="path170"/>
      <path
        className="st7"
        d="m 131.36869,19.23007 c 0,7 -5.7,12.8 -12.8,12.8 -7,0 -12.8,-5.7 -12.8,-12.8 0,-7 5.7,-12.8000002 12.8,-12.8000002 7.1,0 12.8,5.8000002 12.8,12.8000002 z"
        id="path172"/>
      <path
        className="st3"
        d="m 107.46869,32.83007 c 0,5.9 -4.8,10.8 -10.800004,10.8 -5.9,0 -10.8,-4.8 -10.8,-10.8 0,-5.9 4.8,-10.8 10.8,-10.8 5.900004,0 10.800004,4.8 10.800004,10.8 z"
        id="path174"/>
      <path
        className="st1"
        d="m 73.168686,51.93007 c 0,9 -7.3,16.3 -16.3,16.3 -9,0 -16.3,-7.3 -16.3,-16.3 0,-9 7.3,-16.3 16.3,-16.3 9,0 16.3,7.3 16.3,16.3 z"
        id="path176"/>
      <path
        className="st2"
        d="m 41.568686,66.63007 c 0,6.1 -5,11.1 -11.1,11.1 -6.1,0 -11.1,-5 -11.1,-11.1 0,-6.1 4.9,-11.1 11.1,-11.1 6.1,0 11.1,5 11.1,11.1 z"
        id="path178"/>
      <path
        className="st5"
        d="m 30.768686,18.53007 c 0,4.8 -3.9,8.6 -8.6,8.6 -4.8,0 -8.6,-3.9 -8.6,-8.6 0,-4.8 3.9,-8.6000002 8.6,-8.6000002 4.8,0 8.6,3.9000002 8.6,8.6000002 z"
        id="path180"/>
      <path
        className="st1"
        d="m 30.468686,35.93007 c 0,4.1 -3.3,7.4 -7.4,7.4 -4.1,0 -7.4,-3.3 -7.4,-7.4 0,-4.1 3.3,-7.4 7.4,-7.4 4.1,0 7.4,3.3 7.4,7.4 z"
        id="path182"/>
</g>
</svg>
);

export default IconInstitutionLandesinkluClearing;

IconInstitutionLandesinkluClearing.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
};
