import * as React from 'react';
import PropTypes from 'prop-types';

const IconX = ({
    strokeWidth = '2',
    height = '24',
    width = '24',
    className = '',
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`feather feather-x ${className}`}
    >
        <line x1="18" y1="6" x2="6" y2="18" />
        <line x1="6" y1="6" x2="18" y2="18" />
    </svg>
);

export default IconX;

IconX.propTypes = {
    strokeWidth: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    className: PropTypes.string,
};
