import React from 'react';
import i18n from '../../i18n';
import IconContactPerson from '../../assets/images/icons/IconContactPerson';
import format from './helper';
/* eslint-disable */

/**
 *
 * @param {object} markerContent
 * @param {object} styles
 * @returns {JSX.Element}
 */
const koopvContactPerson = (markerContent, styles) => {
  let firstname = markerContent?.firstname;
  let lastname = markerContent?.lastname;
  let email = markerContent?.email;
  let phoneCode = markerContent?.phoneCode;
  let phoneNumber = markerContent?.phoneNumber;

  if (typeof markerContent?.firstname === 'object') {
    firstname = markerContent[0]?.firstname;
    lastname = markerContent[0]?.lastname;
    email = markerContent[0]?.email;
    phoneCode = markerContent[0]?.phoneCode;
    phoneNumber = markerContent[0]?.phoneNumber;
  }


  return (
    <div className={styles.infoRow}>
      <div
        className={styles.contact}
      >
        <div>
          <div className={styles.label}>
            {i18n.t('location.contactPerson')}
          </div>
          {(firstname || lastname)
            && (
              <div className={styles.details}>
                <IconContactPerson
                  width="24px"
                  height="24px"
                  strokeWidth="2.5"
                />
                <div>
                  {firstname}
                  {' '}
                  {lastname}
                  <br/>
                  {email}
                  <br/>
                  {phoneCode}
                  {' '}
                  {phoneNumber}

                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );

};

/**
 *
 * @param {object} markerContent
 * @param {object} styles
 * @returns {JSX.Element}
 */
const koopvWorkingGoal = (markerContent, styles) => {
  return (
    <div className={styles.infoRow}>
      <div
        className={styles.contact}
      >
        <div>
          <div className={styles.label}>
            {i18n.t('location.workGoal')}
          </div>
          {markerContent?.workGoal
            && (
              <ul className={styles.details}>
                {format(markerContent?.workGoal)}
              </ul>
            )}
        </div>
      </div>
    </div>
  );
};

/**
 *
 * @param {object} markerContent
 * @param {object} styles
 * @returns {JSX.Element}
 */
const koopvWorkFocus = (markerContent, styles) => {
  return (
    <div className={styles.infoRow}>
      <div
        className={styles.contact}
      >
        <div>
          <div className={styles.label}>
            {i18n.t('location.workFocus')}
          </div>
          {markerContent?.workFocus
            && (
              <ul className={styles.details}>
                {format(markerContent?.workFocus)}
              </ul>
            )}
        </div>
      </div>
    </div>
  );

};

/**
 *
 * @param {object} markerContent
 * @param {object} styles
 * @returns {JSX.Element}
 */
const koopvEvent = (markerContent, styles) => {
  return (
    <div className={styles.infoRow}>

      <div
        className={styles.contact}
      >
        <div>
          <div className={styles.label}>
            {i18n.t('location.event')}
          </div>
          <ul className={styles.details}>
            {markerContent?.event.map((event, index) => {
              const dateString = event.date ? `${event.date} | ` : '';

              if (event.name) {
                return <li key={index}>{dateString}{event.name} <br/> {event.description}</li>;
              }

              return <React.Fragment key={index} />;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

/**
 *
 * @param {object} markerContent
 * @param {object} styles
 * @returns {JSX.Element}
 */
const koopvPartners = (markerContent, styles) => {
  return (
    <div className={styles.infoRow}>
      <div
        className={styles.contact}
      >
        <div>
          <div className={styles.label}>
            {i18n.t('location.partners')}
          </div>
          {markerContent?.partners
            && (
              <ul className={styles.details}>
                {format(markerContent?.partners)}
              </ul>
            )}
        </div>
      </div>
    </div>
  );
};

/**
 *
 * @param {object} markerContent
 * @param {object} styles
 * @returns {JSX.Element}
 */
const koopvSchoolCounts = (markerContent, styles) => {
  return (
    <div className={styles.infoRow}>
      <div
        className={styles.contact}
      >
        <div>
          <div className={styles.label}>
            {i18n.t('location.schoolCounts')}
          </div>
          {markerContent?.schoolCounts
            && (
              <ul className={styles.details}>
                {markerContent?.schoolCounts.map((schoolCount, index) => {

                  if (markerContent?.schoolCounts.length !== index + 1) return <li
                    key={schoolCount + index}>{schoolCount}</li>;

                  return <React.Fragment key={'null'}/>;
                })}
              </ul>
            )}
        </div>
      </div>
    </div>
  );
};

export {
  koopvContactPerson,
  koopvWorkingGoal,
  koopvWorkFocus,
  koopvEvent,
  koopvPartners,
  koopvSchoolCounts,
}
